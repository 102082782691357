import React from 'react'
import './email.scss'

const Email = () => {
  return (
    <div className='email'>
      <div className='email_item'>
      <a href="mailto:karencai.dev@gmail.com">karencai.dev@gmail.com</a>
      </div>
     
    </div>
  )
}

export default Email